import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ApexCharts from 'apexcharts';
import ResourceChartsService, { BACKUPS_CHARTS } from '@/services/resource-charts/resource-charts.service';
import UtilsService from '@/services/utils/utils.service';
let OrgDashboardPage = class OrgDashboardPage extends Vue {
    constructor() {
        super(...arguments);
        this.topChartsLoading = false;
        this.botChartsLoading = false;
        this.orgData = {
            records: 0,
            size: '0',
            backups: 0,
            api_limit: 0,
            bulk_limit: 0,
        };
        this.orgID = '';
        this.service = ResourceChartsService;
        this.utils = UtilsService;
        this.chartsRenders = {};
    }
    async mounted() {
        if (!this.$route.params.id || !this.$route.params.id.length) {
            await this.$router.replace({ name: 'main.home' });
            return;
        }
        this.orgID = this.$route.params.id;
        this.chartsRenders.dailyAPI = new ApexCharts(document.querySelector('#daily_api'), BACKUPS_CHARTS.API_CHART);
        this.chartsRenders.dailyBulkAPI = new ApexCharts(document.querySelector('#daily_bulk_api'), BACKUPS_CHARTS.BULK_CHART);
        this.chartsRenders.backupsSize = new ApexCharts(document.querySelector('#backups_size'), BACKUPS_CHARTS.SIZE);
        this.chartsRenders.backupsRecords = new ApexCharts(document.querySelector('#backups_records'), BACKUPS_CHARTS.RECORDS);
        this.chartsRenders.backupsSizeTime = new ApexCharts(document.querySelector('#size_time'), BACKUPS_CHARTS.SIZE_TIME);
        this.chartsRenders.backupsRecordsTime = new ApexCharts(document.querySelector('#records_time'), BACKUPS_CHARTS.RECORDS_TIME);
        await Promise.all([this.renderAllCharts(), this.fetchSFResources(), this.fetchBackupsResources()]);
    }
    async fetchSFResources() {
        this.topChartsLoading = true;
        try {
            const { apiRequest, bulkBatch } = await this.service.getSFOrgResources(this.orgID);
            const { number: recordsCount } = await this.service.getOrgRecordsCount(this.orgID);
            const { size } = await this.service.getOrgBackupsSize(this.orgID);
            const { number: backups } = await this.service.getOrgBackupsCount(this.orgID);
            const sizes = await this.service.getBackSizeByDate(this.orgID);
            const records = await this.service.getRecordsByDate(this.orgID);
            const noEmptyCharts = {
                records_chart: this.checkAllZeros(records),
                size_chart: this.checkAllZeros(sizes),
            };
            this.orgData.records = recordsCount;
            this.orgData.size = this.utils.bytesToSizeString(size);
            this.orgData.backups = backups;
            this.orgData.api_limit = apiRequest.limit;
            this.orgData.bulk_limit = bulkBatch.limit;
            await this.chartsRenders.dailyAPI.updateOptions({
                ...BACKUPS_CHARTS.API_CHART,
                series: [
                    {
                        name: 'Limit',
                        data: apiRequest.values.map(val => [new Date(val.date).getTime(), `${apiRequest.limit}`]),
                    },
                    {
                        name: localStorage.getItem('org_name'),
                        type: 'area',
                        data: apiRequest.values.map(val => [new Date(val.date).getTime(), `${val.value || 0}`]),
                    },
                ],
            });
            await this.chartsRenders.dailyBulkAPI.updateOptions({
                ...BACKUPS_CHARTS.BULK_CHART,
                series: [
                    {
                        name: 'Limit',
                        data: bulkBatch.values.map(val => [new Date(val.date).getTime(), `${bulkBatch.limit}`]),
                    },
                    {
                        name: localStorage.getItem('org_name'),
                        type: 'area',
                        data: bulkBatch.values.map(val => [new Date(val.date).getTime(), `${val.value || 0}`]),
                    },
                ],
            });
            await this.chartsRenders.backupsSizeTime.updateOptions({
                ...BACKUPS_CHARTS.SIZE_TIME,
                series: [
                    {
                        name: localStorage.getItem('org_name'),
                        data: noEmptyCharts.size_chart ? sizes.map(s => [new Date(s.date).getTime(), `${s.value || 0}`]) : [],
                    },
                ],
                noData: {
                    text: 'No data to display.',
                    style: {
                        color: '#002d3f',
                    },
                },
            });
            await this.chartsRenders.backupsRecordsTime.updateOptions({
                ...BACKUPS_CHARTS.RECORDS_TIME,
                series: [
                    {
                        name: localStorage.getItem('org_name'),
                        data: noEmptyCharts.records_chart ? records.map(s => [new Date(s.date).getTime(), `${s.value || 0}`]) : [],
                    },
                ],
                noData: {
                    text: 'No data to display.',
                    style: {
                        color: '#002d3f',
                    },
                },
            });
            this.topChartsLoading = false;
        }
        catch (err) {
            this.topChartsLoading = false;
            this.$toast.error(err.message);
        }
    }
    async fetchBackupsResources() {
        this.botChartsLoading = true;
        try {
            const records = await this.service.getBackupsRecordsCount(this.orgID);
            const sizes = await this.service.getBackupsSizes(this.orgID);
            this.botChartsLoading = false;
            await this.renderBackupCharts({ records, sizes });
        }
        catch (err) {
            this.botChartsLoading = false;
            this.$toast.error(err.message);
        }
    }
    async renderAllCharts() {
        await this.chartsRenders.dailyAPI.render();
        await this.chartsRenders.dailyBulkAPI.render();
        await this.chartsRenders.backupsSize.render();
        await this.chartsRenders.backupsRecords.render();
        await this.chartsRenders.backupsSizeTime.render();
        await this.chartsRenders.backupsRecordsTime.render();
    }
    async renderBackupCharts(data) {
        const { records, sizes } = data;
        await this.chartsRenders.backupsSize.updateOptions({
            ...BACKUPS_CHARTS.SIZE,
            series: [
                {
                    data: sizes.map(val => ({
                        x: val.value ? `${val.name}: ${this.utils.bytesToSizeString(+val.value)}` : `${val.name}: 0`,
                        y: val.value ? +val.value : 0,
                    })),
                },
            ],
            noData: {
                text: 'No data to display.',
                style: {
                    color: '#002d3f',
                },
            },
        }, false, true);
        await this.chartsRenders.backupsRecords.updateOptions({
            ...BACKUPS_CHARTS.RECORDS,
            series: [
                {
                    data: records.map(val => ({
                        x: val.value ? `${val.name}: ${+val.value}` : `${val.name}: 0`,
                        y: val.value ? +val.value : 0,
                    })),
                },
            ],
            noData: {
                text: 'No data to display.',
                style: {
                    color: '#002d3f',
                },
            },
        }, false, true);
    }
    // eslint-disable-next-line class-methods-use-this
    checkAllZeros(data) {
        // eslint-disable-next-line no-nested-ternary
        return data.some(el => (el.value ? el.value > 0 : el.number ? el.number > 0 : null));
    }
};
OrgDashboardPage = __decorate([
    Component({
        components: {},
    })
], OrgDashboardPage);
export default OrgDashboardPage;
